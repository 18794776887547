
    import { defineComponent } from 'vue';  
    import store from '@/store'
    import StatusCircle from '@/components/StatusCircle.vue';
    import NeuroProgress from '@/components/NeuroProgress.vue';
    import ProgressBar from '@/components/ProgressBar.vue';
    import ChartNeuro from '@/components/ChartNeuro.vue';
    import ProgressCircle from '~/components/ProgressCircle/ProgressCircle.vue';
    import moment from 'moment-timezone';
    import VideoPlayer from '~/components/VideoPlayer/VideoPlayer.vue';

    export default defineComponent({
        name : 'WatNeuroMonitor',
        components:{
            StatusCircle,
            NeuroProgress,
            ProgressBar,
            ProgressCircle,
            ChartNeuro,
            VideoPlayer
        },
        data:()=>{
            return{
                selectedTab: 0,
                studyData: {
                    title: '',
                    objetive: '',
                    sector: '',
                    testerNum: 0,
                    date: ''
                },
                neuroData: {
                    creationDate: '',
                    globalIndicator: 0,
                    brand_recognition: 0,
                    brand_contribution: 0,
                    attention: 0,
                    activation: 0,
                    positivity: 0,
                    confusion: 0,
                    commitment: 0,
                    rejection: 0,
                    attributes:{"Sostenibilidad":0,"Desprecio":0,"Calidad":0,"Interés":0, "Indiferencia":0},
                    frameTimes:[{
                        emotionalImpact:0,
                        attention:0,
                        frameTime:0
                    },
                    {
                        emotionalImpact:0,
                        attention:0,
                        frameTime:0
                    },
                    {
                        emotionalImpact:0,
                        attention:0,
                        frameTime:0
                    },
                    {
                        emotionalImpact:0,
                        attention:0,
                        frameTime:0
                    },
                    {
                        emotionalImpact:0,
                        attention:0,
                        frameTime:0
                    },
                    {
                        emotionalImpact:0,
                        attention:0,
                        frameTime:0
                    },
                    {
                        emotionalImpact:0,
                        attention:0,
                        frameTime:0
                    },
                    {
                        emotionalImpact:0,
                        attention:0,
                        frameTime:0
                    },
                    {
                        emotionalImpact:0,
                        attention:0,
                        frameTime:0
                    }]
                },
                taskVideo: {
                    url: '',
                    videoTime: 0,
                    totalDuration: 0
                }
            }
        },
        computed:{
            attentionData(){
                const data = this.neuroData
                return data.frameTimes.map((item: any) => item.attention);
            },
            emotionalImpactData(){
                const data = this.neuroData
                return data.frameTimes.map((item: any) => Math.round(item.emotionalImpact));
            },
            timeFramesData(){
                const data = this.neuroData
                return data.frameTimes.map((item: any) => Math.round(item.frameTime / 1000));
            }
        },
        methods:{
            progressClass(percent: any) {
                if(percent < 25) return "red"
                else if(percent < 50) return "yellow"
                else if(percent < 75) return "green"
                return "intenseGreen"
            },
            async fetchNeuroData(){
                await store.dispatch('fetchNeuroMonitor', {
                    contractId: this.$route.params.id,
                })
                .then((data) => {
                    this.neuroData = data.neuroData;
                    this.studyData.title = data.title
                    this.studyData.sector = data.sector
                    this.studyData.objetive = data.studyObjetive
                    this.studyData.testerNum = data.testersFinished
                    this.taskVideo.url = data.taskVideo
                })
            },
            formatDate: (date: any) => {
                return moment(date).format('DD-MM-YYYY')
            },
            neuroVideoExists(){
                return this.taskVideo.url != undefined && this.taskVideo.url != ""
            },
            videoTimeUpdated(event: any) {
                this.taskVideo.videoTime = event
            },
            setVideoDuration(event: any) {
                this.taskVideo.totalDuration = event
            }
        },
        mounted() {
            this.fetchNeuroData();
        }
    })
  